import React from "react";

const Faq = () => {
  return (
    <>
      <h2 id="data">What data is synced?</h2>
      {/* <p>
        Gargle Data Sync only sends the information that is needed to provide
        you the best possible information in the future. That is why we only
        sync what is important for this goal:
      </p> */}
      <ul>
        <li>Patient Information</li>
        <li>Patient Appointments</li>
        <li>Patient Transactions</li>
        <li>Treatment Plans</li>
      </ul>
      <br />
      <h2>How long does the sync take?</h2>
      <p>
        The first sync can take several hours depending on the number of records
        in your database.
      </p>
      <p>
        After the first sync, only new data is synced so each sync will be much faster.
      </p>
      <h2> Will it slow down my computer?</h2>
      <p>Possibly during the first sync. After that your computer should function normally</p>
    </>
  );
};

export default Faq;
