import React from "react";
import logo from "./logo.png";
import { Link } from "react-router-dom";

const TopMenu = ({ children }) => {
  return (
    <div className="navbar fixed-top navbar-expand-lg navbar-light bg-light top-menu">
      <a
        className="navbar-brand"
        href="https://gargle.com"
        target="_blank"
        rel="noreferrer"
      >
        <img src={logo} alt="Gargle Logo TM" className="logo" />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <Link to={"/"}>Installation</Link>
          {/* <Link to={"/config"}>Configuration</Link>
          <Link to={"/update"}>Update</Link> */}
          <Link to={"/faq"}>FAQ</Link>
          <Link to={"/troubleshooting"}>Troubleshooting</Link>
          <a
            href="https://www.gargle.com/contact-us"
            target="_blank"
            rel="noreferrer"
          >
            Support
          </a>
        </ul>
        <form
          id="contactInfo"
          className="form-inline my-2 my-lg-0 contact-info"
        >
          <p>Tel: 888-842-7453</p>
          <p>
            Email:{" "}
            <a className="email" href="mailto:info@gargle.com">
              {" "}
              info@gargle.com{" "}
            </a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default TopMenu;
