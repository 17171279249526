import React from "react";
import $ from "jquery";

import ReactPlayer from 'react-player'

import uac1 from "./img/uac1.jpg";
import uac2 from "./img/uac2.jpg";
import uac3 from "./img/uac3.jpg";
import uac4 from "./img/uac4.jpg";
import uac5 from "./img/uac5.jpg";

import errorOD from "./img/errorOD.jpg";
import errorES from "./img/errorES.jpg";

const CommonIssues = () => {
  $("html").animate({ scrollTop: 0 }, "slow");

  const scrollTo = (elem) => {
    $("html").animate(
      {
        scrollTop: $(elem).offset().top - 70,
      },
      "slow"
    );
  };
  const videoFilePath = 'https://s3.us-west-2.amazonaws.com/widget.gargle.com/uac.mov';
  return (
    <>
      <h2>Content</h2>
      <button className="button-link" onClick={() => scrollTo($("#runas")[0])}>
        Run as Administrator
      </button>
      {/* <br /> */}
      {/* <button className="button-link" onClick={() => scrollTo($("#data")[0])}>
        What data is synced?
      </button> */}
      <br />
      <button
        className="button-link"
        onClick={() => scrollTo($("#errorod")[0])}
      >
        Connection error in Open Dental
      </button>
      <br />
      <button
        className="button-link"
        onClick={() => scrollTo($("#errores")[0])}
      >
        Connection error in EagleSoft
      </button>
      <br />
      <br />
      <br />

      <h2 id="runas">Run as Administrator</h2>
      
      <ReactPlayer url={videoFilePath} width="50%" height="100%" controls={true} />
      <h6>Go to the app folder and right click, then Properties</h6>
      <img src={uac1} alt="Properties" />
      <h6>Click the Security Tab, then Edit</h6>
      <img src={uac2} alt="Edit Properties" />
      <h6>Click on Add</h6>
      <img src={uac3} alt="Add User" />
      <h6>Write the word “Everyone” and click Ok</h6>
      <img src={uac4} alt="Add Everyone" />
      <h6>
        Then, click on "Everyone" (it will now be an option) and then Allow Full
        Control
      </h6>
      <img src={uac5} alt="Full Control" />
      <br />
      <br />
      <p>Click Ok and then Ok again.</p>


      <br />
      <h2 id="errorod">Connection error in Open Dental</h2>
      <p>
        The message <b>"Unable to connect"</b> will show until you enter the
        correct login credentials.
      </p>
      <img src={errorOD} alt="Error Open Dental" />
      <br />
      <br />
      <p>If you continue seeing this error after clicking Ok, please check:</p>
      <ul>
        <li>
          All the information entered is accurate (Server, Port, Database User
          and Password)
        </li>
        <li>You have access to the server</li>
        <li>Open Dental is properly installed and you are able to use it</li>
      </ul>

      <br />
      <br />
      <h2 id="errores">Connection error in EagleSoft</h2>
      <p>
        The message <b>"Status: Waiting..."</b> will show until you enter the
        correct login credentials.
      </p>
      <img src={errorES} alt="Error EagleSoft" />
      <br />
      <br />
      <p>
        If you continue seeing this message after clicking Ok or you get a
        different error, please check:
      </p>
      <ul>
        <li>
          All the information entered is accurate (Server, User and Password)
        </li>
        <li>You have access to the server</li>
        <li>EagleSoft is properly installed and you are able to use it</li>
      </ul>
      <p>
        EagleSoft will prompt a descriptive error, so you should be able to
        identify if it's a probelm accessing your server or incorrect
        credentials.{" "}
        <b>
          If you still cannot configure your application, please contact Gargle.
        </b>
      </p>
    </>
  );
};

export default CommonIssues;
