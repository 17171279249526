import React from "react";
import "./App.css";
import { Route } from "react-router-dom";

import TopMenu from "./TopMenu";
import Installation from "./Content/Installation";
import CommonIssues from "./Content/CommonIssues";
import Faq from "./Content/Faq";
// import Configuration from "./Content/Configuration";
// import Update from "./Content/Update";

function App(props) {
  return (
    <>
      <TopMenu />
      <div className="container-fluid main-panel">
        <Route path="/" exact component={Installation} />
        {/* <Route path="/config" exact component={Configuration} /> */}
        {/* <Route path="/update" exact component={Update} /> */}
        <Route path="/troubleshooting" exact component={CommonIssues} />
        <Route path="/faq" exact component={Faq} />
      </div>
    </>
  );
}

export default App;
