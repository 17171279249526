import React, { useEffect } from "react";
import $ from "jquery";

import firstRun from "./img/firstRun.jpg";
import preview1 from "./img/preview1.jpg";
import previewOD from "./img/previewOD.jpg";
import previewES from "./img/previewES.jpg";

import esAuthenticated1 from "./img/esAuthenticated1.jpg";
import esAuthenticated2 from "./img/esAuthenticated2.jpg";
import esAuthenticated3 from "./img/esAuthenticated3.jpg";

const Configuration = (e) => {
  $("html").animate({ scrollTop: 0 }, "slow");

  const handleAccordionOnClick = (e) => {
    const h2s = $(".accordion>h2");
    const divs = $(".accordion>div");

    h2s.not(e.target).removeClass("active");
    $(e.target).toggleClass("active");
    divs.not($(e.target).next()).slideUp();
    $(e.target).next().slideToggle();
    return false; //Prevent the browser jump to the link anchor
  };

  useEffect(() => {
    $(".accordion>div").hide(); //Hide/close all containers
  }, []);

  return (
    <>
      <h2>Configuring the Application</h2>
      <p>
        Getting the application up and running is easy. When you first open the
        application, you will be asked to enter your Account ID.
        <br />
        <b>You can get this ID from Gargle.</b>
      </p>
      <img src={firstRun} alt="First Run" />
      <br />
      <br />
      <p>
        Once you have entered your ID, click on Save. You will be taken to the
        Gargle Data Sync main screen.
      </p>
      <p>
        Since this is the first time, the application doesn’t know what Practice
        Management Software you want to start syncing.{" "}
        <p></p>
        <b>Gargle Data Sync supports Open Dental and EagleSoft.</b>
      </p>
      <p>
        First, select the software from the field “Connected Software”, and then
        click “Configure”.
      </p>
      <img src={preview1} alt="Application Opened" />
      <br />
      <br />
      <p>
        The configuration form may vary depending on the software you selected.
        In this form, you need to enter the database connection settings in
        order to proceed.
      </p>
      <div className="accordion">
        <h2 className="acc_trigger" onClick={handleAccordionOnClick}>
          Open Dental Configuration
        </h2>
        <div className="acc_container">
          <h6>Open Dental</h6>
          <img src={previewOD} alt="Open Dental" />
          <br />
          <br />
          If the software is installed in the same computer as the Gargle Data
          Sync, you will most likely be able to leave the default values and
          click Ok.
        </div>
      </div>
      <br />
      <div className="accordion">
        <h2 className="acc_trigger" onClick={handleAccordionOnClick}>
          EagleSoft Configuration
        </h2>
        <div className="acc_container">
          <h6>EagleSoft</h6>
          <img src={previewES} alt="EagleSoft" />
          <br />
          <br />
          While configuring your EagleSoft integration, it will ask for your
          Server, User Id and Password. It is recommended you install the Gargle
          Data Sync Widget in the same computer as your EagleSoft server, so you
          shouln't need to change the Server value.
          <br />
          <br />
          To find your User Id, open EagleSoft, then go to Lists &#8594;
          Providers / Staff. Select your user and click Edit. At the top, you
          will see the ID of your user. Use that value for the User Id box and
          the password associated to the selected user.
          <br />
          <br />
          After entering your Server, User Id and Password, EagleSoft requires
          one extra step to enable the integration to access your information.
          <b> Keep the Gargle Data Widget configuration window opened</b> and
          follow the next steps:
          <br />
          <br />
          <img src={esAuthenticated1} alt="EagleSoft" />
          <br />
          <br />
          <img src={esAuthenticated2} alt="EagleSoft" />
          <br />
          <br />
          <img src={esAuthenticated3} alt="EagleSoft" />
          <br />
          <br />
          After enabling access, go back to Gargle Data Sync and click the Ok
          button again. This is it!
        </div>
      </div>
      <br />
      <i>
        * Contact Gargle or your Network Administrator if you need help
        configuring this section.
      </i>
      <br />
      <br />

      <p>
        Click on <b>"Start Syncing"</b> to initialize the process.{" "}
        Click "Minimize to Tray" to minimize the application
      </p>

    </>
  );
};

export default Configuration;
