import React from "react";
import $ from "jquery";
import ReactPlayer from 'react-player'

// import launch from "./img/launch.jpg";
import download from "./img/download.png";
import Configuration from './Configuration';

const Installation = () => {
  $("html").animate({ scrollTop: 0 }, "slow");
  const videoFilePath = 'https://s3.us-west-2.amazonaws.com/widget.gargle.com/install.mov';
  return (
    <>
      <h2>Quick Note</h2>
      <p>
        * Make sure you perform the installation on the same computer your
        Practice Managment Software is installed.
      </p>
      <br />
      <h2>1 . Install Gargle Data Sync</h2>
      <a href="https://s3.us-west-2.amazonaws.com/widget.gargle.com/GargleDataSyncCore-Setup.exe">
      <button className="download-button">
        <img src={download} className="download" alt="Download" />
        <b>
        Download Gargle Data Sync
        </b>
      </button>
      </a>
      <br />
      <br />
      <ReactPlayer url={videoFilePath} width="50%" height="100%" controls={true} />
      <br />
      <Configuration />

      <br />
      

    </>
  );
};

export default Installation;
